import React, { useState, useEffect } from 'react';
import { getStyleImage } from './architecturalStyles';

const StyleBox = ({ style, mobileImageClass }) => {
  if (!style) return null;

  const imageUrl = getStyleImage(style);

  if (!imageUrl) return null;

  return (
    <div className={`mt-4 relative overflow-hidden rounded-lg shadow-md h-32 ${mobileImageClass || ''}`}>
      <img 
        src={imageUrl}
        alt={style}
        className="absolute inset-0 w-full h-full object-cover"
      />
      <div className="absolute top-0 left-0 right-0 p-2 z-10">
        <p className="text-base md:text-lg font-light text-gray-800 flex items-center">
          <span className="italic font-normal">{style}</span>
        </p>
      </div>
    </div>
  );
};
//disregard this comment
const AgentContactBox = ({ agent, propertyStyle, mobileImageClass, isMobile }) => {
  console.log('Agent data received in AgentContactBox:', JSON.stringify(agent, null, 2));

  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (isMobile) {
      const handleScroll = () => {
        const scrollPosition = window.pageYOffset;
        setIsExpanded(scrollPosition > 100);
      };

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [isMobile]);

  if (!agent) {
    console.log('Agent data is null or undefined');
    return (
      <div className="bg-white p-4 rounded-lg shadow-md sticky top-4">
        <p>Agent information not available</p>
        {propertyStyle && <StyleBox style={propertyStyle} />}
      </div>
    );
  }

  if (isMobile) {
    return (
      <div className={`fixed bottom-4 left-4 right-4 bg-[#201c1c] bg-opacity-90 p-2 sm:p-3 md:p-4 shadow-md transition-all duration-300 rounded-lg ${
        isExpanded ? 'h-auto' : 'h-24 sm:h-26 md:h-28 lg:h-32'
      }`}>
        <div className="flex items-center justify-between h-full">
          {agent.image_url ? (
            <img 
              src={agent.image_url} 
              alt={agent.display_name || 'Agent'}
              className="w-16 h-16 sm:w-18 sm:h-18 md:w-20 md:h-20 lg:w-22 lg:h-22 rounded-full object-cover flex-shrink-0"
            />
          ) : (
            <div className="w-16 h-16 sm:w-18 sm:h-18 md:w-20 md:h-20 lg:w-22 lg:h-22 rounded-full bg-gray-200 flex items-center justify-center flex-shrink-0">
              <span className="text-gray-500 text-sm sm:text-base md:text-lg">No Image</span>
            </div>
          )}
          <div className="flex-grow mx-2 sm:mx-3 md:mx-4 min-w-0 flex flex-col justify-center">
            <h3 className="text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl text-white font-light truncate">{agent.display_name || 'Agent Name'}</h3>
            <p className="text-sm sm:text-base md:text-lg lg:text-xl text-white font-light truncate">{agent.business_name || 'Business Name'}</p>
          </div>
          <button
            className="bg-[#dcb482] text-black py-2 sm:py-2.5 md:py-3 lg:py-3.5 px-3 sm:px-4 md:px-5 lg:px-6 rounded-lg hover:bg-[#c9a06e] transition duration-300 font-light whitespace-nowrap text-sm sm:text-base md:text-lg lg:text-xl flex-shrink-0 h-fit"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            Contact Agent
          </button>
        </div>
      </div>
    );
  }

  // Desktop version (unchanged)
  return (
    <div className="sticky top-4">
      <div className="bg-[#201c1c] p-4 rounded-lg shadow-md">
        <div className="flex items-center mb-4">
          {agent.image_url ? (
            <img 
              src={agent.image_url} 
              alt={agent.display_name || 'Agent'}
              className="w-16 h-16 rounded-full mr-4 object-cover"
            />
          ) : (
            <div className="w-16 h-16 rounded-full mr-4 bg-gray-200 flex items-center justify-center">
              <span className="text-gray-500">No Image</span>
            </div>
          )}
          <div>
            <h3 className="text-lg text-white font-light">{agent.display_name || 'Agent Name'}</h3>
            <p className="text-sm text-white font-light">{agent.business_name || 'Business Name'}</p>
          </div>
        </div>
        <button className="w-full bg-[#dcb482] text-black py-2 px-4 rounded-lg hover:bg-[#c9a06e] transition duration-300 font-light">
          Contact Agent
        </button>
      </div>
      {propertyStyle && <StyleBox style={propertyStyle} mobileImageClass={mobileImageClass} />}
    </div>
  );
};

export default AgentContactBox;