import React, { useState, useEffect, useRef, useContext } from 'react';
import { UserContext } from '../UserContext';
import { X } from 'lucide-react';
import './GoogleButton.css';  // Import the new CSS file

const AuthModal = ({ isOpen, onClose, onSubmit, mode, onSwitchMode }) => {
  const { updateUser } = useContext(UserContext);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const modalRef = useRef(null);

  const resetForm = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPassword('');
    setError('');
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      console.log('API URL:', process.env.REACT_APP_API_URL);
      const endpoint = `${process.env.REACT_APP_API_URL}${mode === 'signup' ? '/api/auth/register' : '/api/auth/login'}`;
      console.log('Full endpoint:', endpoint);
      console.log('Request body:', { email, password });
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ firstName, lastName, email, password }),
        credentials: 'include'
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('token', data.token);
        onSubmit(data.user);
        handleClose();
      } else {
        const errorData = await response.json();
        if (response.status === 401) {
          setError('Incorrect email or password. Please try again.');
        } else if (response.status === 409 && mode === 'signup') {
          setError('An account with this email already exists. Please log in or use a different email.');
        } else {
          setError(errorData.message || 'An error occurred. Please try again.');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setError('An unexpected error occurred. Please try again.');
    }
  };

  const handleGoogleSignIn = () => {
    window.open(`${process.env.REACT_APP_API_URL}/api/auth/google`, '_self');
  };

  const handleGoogleSignInCallback = async (response) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/google`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: response.credential }),
        credentials: 'include'
      });
      const data = await res.json();
      if (res.ok) {
        updateUser(data.user);
        onClose();
      } else {
        throw new Error(data.message || 'Google Sign-In failed');
      }
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      resetForm();
    }
  }, [isOpen, mode]);

  if (!isOpen) return null;

  const inputClasses = "w-full px-3 py-2 bg-black text-white border rounded-md focus:outline-none transition-all duration-200 ease-in-out";
  const emptyInputClasses = "bg-gray-950"; // Changed from bg-gray-900 to bg-gray-950
  const focusClasses = "focus:ring-[0.5px] focus:ring-white focus:border-white";
  const thinBorderClasses = "border-[0.5px]";

  const getInputClasses = (value) => {
    return `${inputClasses} ${thinBorderClasses} ${value ? '' : emptyInputClasses} ${focusClasses}`;
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-50 auth-modal" onClick={(e) => e.stopPropagation()}>
      <div ref={modalRef} className="relative p-8 bg-black w-full max-w-md m-auto rounded-2xl shadow-lg border border-gray-700" onClick={(e) => e.stopPropagation()}>
        <button 
          onClick={handleClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-white transition-colors duration-200"
        >
          <X size={24} />
        </button>
        <h2 className="text-2xl font-bold text-white mb-6">{mode === 'signup' ? 'Create an account' : 'Welcome back'}</h2>
        {error && (
          <div className="mb-4 p-3 bg-red-900 text-white rounded-md">
            {error}
          </div>
        )}
        <form onSubmit={handleSubmit} className="space-y-4">
          {mode === 'signup' && (
            <>
              <input
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                className={getInputClasses(firstName)}
              />
              <input
                type="text"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                className={getInputClasses(lastName)}
              />
            </>
          )}
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className={getInputClasses(email)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className={getInputClasses(password)}
          />
          <button 
            type="submit" 
            className="w-full bg-white text-black py-2 px-4 rounded-md hover:bg-gray-200 transition duration-300"
          >
            {mode === 'signup' ? 'Sign Up' : 'Login'}
          </button>
        </form>
        
        <div className="mt-4 text-center text-gray-400 relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-b border-gray-600"></div>
          </div>
          <div className="relative">
            <span className="bg-black px-2 text-sm">Or</span>
          </div>
        </div>
        
        <button 
          className="w-full mt-4 flex justify-center items-center" 
          onClick={handleGoogleSignIn}
        >
          <img 
            src="/web_light_rd_ctn@2x.png" 
            alt="Continue with Google" 
            className="h-10 w-auto"
          />
        </button>
        
        <p className="mt-4 text-center text-gray-400">
          {mode === 'signup' ? 'Already have an account?' : "Don't have an account?"}
          <button 
            onClick={() => onSwitchMode(mode === 'signup' ? 'login' : 'signup')}
            className="ml-2 text-white hover:underline"
          >
            {mode === 'signup' ? 'Log in' : 'Sign up'}
          </button>
        </p>
      </div>
    </div>
  );
};

export default AuthModal;