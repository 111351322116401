import React, { useState, useRef, useEffect } from 'react';
import { Search, ChevronLeft, ChevronRight } from 'lucide-react';
import { useScrollPosition } from '../hooks/useScrollPosition';
import { debounce } from 'lodash';

const SearchAndFilters = ({ searchQuery, setSearchQuery, handleSearch, activeFilter, setActiveFilter, filters }) => {
  const filterRef = useRef(null);
  const [showLeftScroll, setShowLeftScroll] = useState(false);
  const [showRightScroll, setShowRightScroll] = useState(true);
  const [isShrunken, setIsShrunken] = useState(false);
  const searchBarRef = useRef(null);
  const scrollPosition = useScrollPosition();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [lastScrollY, setLastScrollY] = useState(0);

  const scrollFilters = (direction) => {
    if (filterRef.current) {
      const scrollAmount = direction === 'left' ? -200 : 200;
      filterRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const checkScrollButtons = () => {
    if (filterRef.current) {
      setShowLeftScroll(filterRef.current.scrollLeft > 0);
      setShowRightScroll(
        filterRef.current.scrollLeft < filterRef.current.scrollWidth - filterRef.current.clientWidth
      );
    }
  };

  useEffect(() => {
    const filterContainer = filterRef.current;
    if (filterContainer) {
      filterContainer.addEventListener('scroll', checkScrollButtons);
      checkScrollButtons();
    }
    return () => {
      if (filterContainer) {
        filterContainer.removeEventListener('scroll', checkScrollButtons);
      }
    };
  }, []);

  useEffect(() => {
    const updateIndicator = () => {
      if (filterRef.current) {
        const activeButton = filterRef.current.querySelector(`button:nth-child(${filters.indexOf(activeFilter) + 1})`);
        if (activeButton) {
          const indicator = filterRef.current.querySelector('.absolute.bottom-0.left-0[class*="h-[2px]"]');
          if (indicator) {
            const isFirstFilter = filters.indexOf(activeFilter) === 0;
            indicator.style.width = `${activeButton.offsetWidth + (isFirstFilter ? activeButton.offsetLeft : 0)}px`;
            indicator.style.transform = `translateX(${isFirstFilter ? 0 : activeButton.offsetLeft}px)`;
          }
        }
      }
    };

    updateIndicator();
    window.addEventListener('resize', updateIndicator);
    return () => window.removeEventListener('resize', updateIndicator);
  }, [activeFilter, filters]);

  useEffect(() => {
    const handleScroll = debounce(() => {
      if (searchBarRef.current) {
        if (isMobile) {
          // Mobile behavior
          const currentScrollY = window.scrollY;
          setIsShrunken(currentScrollY > 350);
        } else {
          // Desktop behavior (unchanged)
          const rect = searchBarRef.current.getBoundingClientRect();
          setIsShrunken(rect.top <= 60);
        }
      }
    }, 10); // 10ms debounce time, adjust as needed

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      handleScroll.cancel(); // Cancel any pending debounce on cleanup
    };
  }, [isMobile]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <header className="w-full bg-cover bg-center bg-no-repeat sticky top-0 z-40 bg-black/50 backdrop-blur-md shadow-sm pointer-events-auto">
      <div className="container mx-auto px-4 py-4">
        <form
          ref={searchBarRef}
          onSubmit={handleSearch}
          className={`relative flex mx-auto items-center transition-all duration-300 z-60 ${
            isMobile
              ? 'h-10 max-w-[300px]'
              : isShrunken
                ? 'h-10 max-w-[400px]'
                : 'h-12 max-w-[601px]'
          } ${isMobile ? 'search-form-mobile' : ''}`}
          style={{
            ...(isMobile && {
              width: isShrunken ? '60%' : '100%',
              maxWidth: '300px',
              transform: 'translateX(0)',
              transition: 'all 0.3s ease-in-out',
            }),
          }}
        >
          <input
            type="text"
            placeholder={isMobile ? "Search by neighborhood..." : "Search by neighborhood, city, or zipcode"}
            className={`w-full py-3 bg-gradient-to-b from-[#0f0f0f] to-[#1a1a1a] text-white rounded-md border border-white border-opacity-20 focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50 transition-all duration-300 ${
              isMobile ? 'text-sm px-2' : 'text-base px-5'
            }`}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button type="submit" className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-white transition-colors">
            <Search size={isMobile ? 16 : isShrunken ? 16 : 18} strokeWidth={1.5} />
          </button>
        </form>
      </div>

      <div className="w-full relative">
        <div className="relative w-full overflow-x-auto scrollbar-hide border-b border-gray-600" ref={filterRef}>
          <div className="flex items-center py-2 pb-2 relative px-4 max-w-none">
            {filters.map((filter) => (
              <button
                key={filter}
                className={`px-3 py-1 text-sm font-medium transition-colors duration-200 whitespace-nowrap ${
                  activeFilter === filter
                    ? 'text-white font-bold'
                    : 'text-gray-400 hover:text-white'
                }`}
                onClick={() => setActiveFilter(filter)}
              >
                {filter}
              </button>
            ))}
            <div className="absolute bottom-0 left-0 right-0 h-[1px] bg-gray-600 opacity-50 z-10"></div>
            <div 
              className="absolute bottom-0 left-0 h-[2px] bg-white transition-all duration-200 z-20"
              style={{
                width: filterRef.current ? filterRef.current.querySelector(`button:nth-child(${filters.indexOf(activeFilter) + 1})`).offsetWidth : 0,
                transform: filterRef.current ? `translateX(${filterRef.current.querySelector(`button:nth-child(${filters.indexOf(activeFilter) + 1})`).offsetLeft}px)` : 'translateX(0)',
              }}
            ></div>
          </div>
        </div>
        {showLeftScroll && (
          <div className="absolute left-0 top-0 bottom-0 w-16 bg-gradient-to-r from-black to-transparent pointer-events-none"></div>
        )}
        {showRightScroll && (
          <div className="absolute right-0 top-0 bottom-0 w-16 bg-gradient-to-l from-black to-transparent pointer-events-none"></div>
        )}
        {showLeftScroll && (
          <button 
            onClick={() => scrollFilters('left')} 
            className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full p-1 z-10"
            aria-label="show previous"
          >
            <ChevronLeft size={20} />
          </button>
        )}
        {showRightScroll && (
          <button 
            onClick={() => scrollFilters('right')} 
            className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full p-1 z-10"
            aria-label="show next"
          >
            <ChevronRight size={20} />
          </button>
        )}
      </div>
    </header>
  );
};

export default SearchAndFilters;