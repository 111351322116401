import React, { useState, useEffect, useRef, useContext } from 'react';
import { Search, Menu, ChevronLeft, ChevronRight, MapPin, Star, Bed, Bath } from 'lucide-react';
import axios from 'axios';
import PropertyModal from './PropertyModal';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from './UserContext';
import './VideoBackground.css';
import { neighborhoods } from './neighborhoods';
import SearchAndFilters from './components/SearchAndFilters';
import GlobalHeader from './components/GlobalHeader';

const formatAddress = (address) => {
  if (typeof address === 'string') return address;
  return `${address.streetAddress}, ${address.city}, ${address.state} ${address.zipcode}`;
};

const VideoHero = ({ children }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, []);

  return (
    <div className="relative h-[60vh] sm:h-[40vh]"> {/* Increased height for mobile */}
      <video
        ref={videoRef}
        autoPlay
        loop
        muted
        playsInline
        className="absolute top-0 left-0 w-full h-full object-cover"
      >
        <source src="/videos/luxury-homes.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>
      <div className="absolute inset-0 flex flex-col justify-center items-center pt-16 sm:pt-0"> {/* Added padding-top for mobile */}
        {children}
      </div>
      <div className="video-overlay"></div>
    </div>
  );
};

const FilterButton = ({ filter, activeFilter, onClick }) => (
  <button
    className={`px-3 py-1 text-sm font-medium transition-colors duration-200 whitespace-nowrap ${
      activeFilter === filter
        ? 'text-white font-bold'
        : 'text-gray-400 hover:text-white'
    }`}
    onClick={() => onClick(filter)}
  >
    {filter}
  </button>
);

const FeaturedEstates = ({ estates, user, onSaveProperty, savedProperties, setSavedProperties, updateUser, onOpenAuthModal, onLoadMore, onPropertyClick }) => {
  const scrollRef = useRef(null);
  const [currentImageIndexes, setCurrentImageIndexes] = useState({});
  const [activeFilter, setActiveFilter] = useState('Featured listings');
  const filterRef = useRef(null);
  const [isFilterSticky, setIsFilterSticky] = useState(false);
  const [showLeftScroll, setShowLeftScroll] = useState(false);
  const [showRightScroll, setShowRightScroll] = useState(true);
  const [filteredEstates, setFilteredEstates] = useState(estates);
  const navigate = useNavigate();
  const location = useLocation();

  const scroll = (direction) => {
    if (scrollRef.current) {
      const { current } = scrollRef;
      const scrollAmount = direction === 'left' ? -current.offsetWidth : current.offsetWidth;
      current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const handlePropertyClick = (property) => {
    onPropertyClick(property);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('propertyId', property.zpid);
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
  };

  const formatAddress = (address) => {
    if (!address) return { street: 'Address not available', cityStateZip: '' };
    
    if (typeof address === 'string') {
      const parts = address.split(', ');
      if (parts.length < 3) return { street: address, cityStateZip: '' };
      
      const street = parts[0];
      const cityStateZip = parts.slice(1).join(', ');
      return { street, cityStateZip };
    }
    
    if (typeof address === 'object') {
      const { streetAddress, city, state, zipcode } = address;
      const street = streetAddress || 'Street address not available';
      const cityStateZip = [city, state, zipcode].filter(Boolean).join(', ');
      return { street, cityStateZip };
    }
    
    return { street: 'Invalid address format', cityStateZip: '' };
  };

  const handleSaveProperty = async (e, propertyId) => {
    e.stopPropagation(); // Prevent the property modal from opening
    if (!user) {
      onOpenAuthModal('signup');
      return;
    }
    try {
      const endpoint = savedProperties.includes(propertyId) ? `${process.env.REACT_APP_API_URL}/api/auth/unsave-property` : `${process.env.REACT_APP_API_URL}/api/auth/save-property`;
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: user.id, propertyId: propertyId.toString() }),
        credentials: 'include'
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.message}`);
      }
      const data = await response.json();
      console.log('Property saved/unsaved:', data);
      setSavedProperties(data.savedPosts);
      // Update user context
      updateUser({ ...user, savedPosts: data.savedPosts });
    } catch (error) {
      console.error('Error saving/unsaving property:', error);
    }
  };

  const handleImageScroll = (estateZpid, direction) => {
    setCurrentImageIndexes(prevIndexes => {
      const currentIndex = prevIndexes[estateZpid] || 0;
      const estate = filteredEstates.find(e => e.zpid === estateZpid);
      if (!estate || !estate.images) return prevIndexes;
      const imagesCount = estate.images.length;
      let newIndex;

      if (direction === 'left') {
        newIndex = (currentIndex - 1 + imagesCount) % imagesCount;
      } else {
        newIndex = (currentIndex + 1) % imagesCount;
      }

      return { ...prevIndexes, [estateZpid]: newIndex };
    });
  };

  const selectedNeighborhoods = [
    'Bel-Air', 'Pacific Palisades', 'Malibu', 'Brentwood', 'Santa Monica', 'Venice', 'Hancock Park',
    'Beverly Crest', 'Beverly Grove', 'Beverlywood', 'Century City', 'Encino', 'Hollywood Hills', 
    'Studio City', 'Westwood'
  ];

  const filters = [
    'Featured listings',
    ...selectedNeighborhoods
  ];

  const scrollFilters = (direction) => {
    if (filterRef.current) {
      const scrollAmount = direction === 'left' ? -200 : 200;
      filterRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const checkScrollButtons = () => {
    if (filterRef.current) {
      setShowLeftScroll(filterRef.current.scrollLeft > 0);
      setShowRightScroll(
        filterRef.current.scrollLeft < filterRef.current.scrollWidth - filterRef.current.clientWidth
      );
    }
  };

  useEffect(() => {
    const filterContainer = filterRef.current;
    if (filterContainer) {
      filterContainer.addEventListener('scroll', checkScrollButtons);
      checkScrollButtons();
    }
    return () => {
      if (filterContainer) {
        filterContainer.removeEventListener('scroll', checkScrollButtons);
      }
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const filterContainer = filterRef.current;
      if (filterContainer) {
        const { top } = filterContainer.getBoundingClientRect();
        setIsFilterSticky(top <= 64); // 64px is the height of the header
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (activeFilter === 'Featured listings') {
      setFilteredEstates(estates);
    } else {
      fetchNeighborhoodProperties(activeFilter);
    }
  }, [activeFilter, estates]);

  const fetchNeighborhoodProperties = async (neighborhood) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/properties/search?search=${encodeURIComponent(neighborhood)}`);
      const validProperties = response.data.map(property => ({
        ...property,
        price: property.price ? `$${property.price.toLocaleString()}` : 'Price not available',
        sqft: property.livingArea ? `${property.livingArea.toLocaleString()} sqft` : 'N/A',
        beds: property.bedrooms || 'N/A',
        baths: property.bathrooms || 'N/A',
      })).filter(property => 
        property.latitude && 
        property.longitude && 
        typeof property.latitude === 'number' && 
        typeof property.longitude === 'number' &&
        !isNaN(property.latitude) && 
        !isNaN(property.longitude)
      );
      setFilteredEstates(validProperties);
    } catch (error) {
      console.error('Error fetching neighborhood properties:', error);
    }
  };

  useEffect(() => {
    const updateIndicator = () => {
      if (filterRef.current) {
        const activeButton = filterRef.current.querySelector(`button:nth-child(${filters.indexOf(activeFilter) + 1})`);
        if (activeButton) {
          const indicator = filterRef.current.querySelector('.absolute.bottom-0.left-0[class*="h-[2px]"]');
          if (indicator) {
            indicator.style.width = `${activeButton.offsetWidth}px`;
            indicator.style.transform = `translateX(${activeButton.offsetLeft}px)`;
          }
        }
      }
    };

    updateIndicator();
    window.addEventListener('resize', updateIndicator);
    return () => window.removeEventListener('resize', updateIndicator);
  }, [activeFilter, filters]);

  return (
    <div className="relative">
      <div className="container mx-auto mb-16 grid w-full max-w-none auto-rows-auto grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 grid-rows-[min-content] justify-center overflow-hidden md:min-h-screen gap-2 mt-4 px-4">
        {filteredEstates.map((estate) => {
          const { street, cityStateZip } = formatAddress(estate.address);
          const isSaved = savedProperties.includes(estate.zpid);
          const currentImageIndex = currentImageIndexes[estate.zpid] || 0;
          return (
            <div 
              key={estate.zpid} 
              className="card-wrapper group pointer-events-auto relative grid aspect-square h-full w-full grid-rows-[1fr_auto] gap-3 rounded-lg sm:aspect-auto sm:grid-rows-[294px_auto] p-1 transform transition-all duration-300 hover:scale-[1.025] hover:shadow-lg cursor-pointer"
              onClick={() => handlePropertyClick(estate)}
            >
              <div className="card relative isolate h-full w-full rounded-lg overflow-hidden">
                <div className="scrollbar-hide pointer-events-auto relative inset-0 flex snap-x snap-mandatory overflow-x-auto w-full object-cover h-full">
                  <img src={estate.images[currentImageIndex]} alt={street} className="h-full w-full flex-none snap-center snap-always overflow-hidden object-cover" />
                </div>
                <button
                  className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full p-1.5 hover:bg-opacity-75 transition-opacity opacity-0 group-hover:opacity-100"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleImageScroll(estate.zpid, 'left');
                  }}
                  aria-label="prev picture"
                >
                  <ChevronLeft size={20} />
                </button>
                <button
                  className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full p-1.5 hover:bg-opacity-75 transition-opacity opacity-0 group-hover:opacity-100"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleImageScroll(estate.zpid, 'right');
                  }}
                  aria-label="next picture"
                >
                  <ChevronRight size={20} />
                </button>
                <div className="absolute top-4 right-4 z-10">
                  <button
                    className="text-white hover:text-yellow-500 transition-colors duration-200"
                    onClick={(e) => handleSaveProperty(e, estate.zpid)}
                  >
                    <Star
                      className={`${isSaved ? 'fill-current' : ''}`}
                      size={24}
                    />
                </button>
                </div>

              </div>
              <div className="group-[.is]/marker:rounded-b-lg group-[.is]/marker:bg-black group-[.is]/marker:px-3 group-[.is]/marker:pb-3">
                <div className="text-property-eyebrow text-white/60">{street}</div>
                <div className="text-property-eyebrow text-white/60">{cityStateZip}</div>
                <div className="mb-0.5 flex items-center gap-2 font-medium">{estate.price}</div>
                <div className="grid grid-cols-[min-content,min-content,1fr] w-full items-center">
                  <span className="whitespace-nowrap">{estate.sqft}</span>
                  <span className="whitespace-nowrap text-white text-[13px] border-r border-gray-600 pr-2">&nbsp;{estate.nextAvailable}</span>
                  <div className="flex items-center gap-3 text-sm pl-2">
                    <div className="flex items-center gap-1">
                      <Bed size={16} className="text-white/60" />
                      <span>{estate.beds}</span>
                    </div>
                    <div className="flex items-center gap-1">
                      <Bath size={16} className="text-white/60" />
                      <span>{estate.baths}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const HeimLuxuryListing = ({ onOpenAuthModal }) => {
  const { user, updateUser } = useContext(UserContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [luxuryEstates, setLuxuryEstates] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const userMenuRef = useRef(null);
  const [savedProperties, setSavedProperties] = useState([]);
  const [isScrolled, setIsScrolled] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [filteredEstates, setFilteredEstates] = useState([]);

  const [isSticky, setIsSticky] = useState(false);
  const searchAndFiltersRef = useRef(null);

  const [activeFilter, setActiveFilter] = useState('Featured listings');
  const filters = [
    'Featured listings',
    'Bel-Air', 'Pacific Palisades', 'Malibu', 'Brentwood', 'Santa Monica', 'Venice', 'Hancock Park',
    'Beverly Crest', 'Beverly Grove', 'Beverlywood', 'Century City', 'Encino', 'Hollywood Hills', 
    'Studio City', 'Westwood'
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (searchAndFiltersRef.current) {
        const { top } = searchAndFiltersRef.current.getBoundingClientRect();
        setIsSticky(top <= 64); // 64px is the height of the header
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const userParam = urlParams.get('user');
    if (userParam) {
      const userData = JSON.parse(decodeURIComponent(userParam));
      updateUser(userData);
      // Clear the URL parameter
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, []);

  useEffect(() => {
    if (user) {
      setSavedProperties(user.savedPosts || []);
    } else {
      setSavedProperties([]);
    }
  }, [user]);

  const fetchLuxuryEstates = async (pageNum = 1) => {
    try {
      setLoading(true);
      console.log('Fetching luxury estates...');
      console.log('Fetching from URL:', `${process.env.REACT_APP_API_URL}/api/properties?page=${pageNum}&limit=40`);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/properties?page=${pageNum}&limit=40`);
      console.log('Received data:', response.data);
      const newProperties = response.data.map(property => ({
        id: property.zpid,
        address: formatAddress(property.address),
        price: property.price ? `$${property.price.toLocaleString()}` : 'Price not available',
        beds: property.bedrooms || 'N/A',
        baths: property.bathrooms || 'N/A',
        sqft: property.livingArea ? `${property.livingArea.toLocaleString()} sqft` : 'N/A',
        images: property.images || [],
        zpid: property.zpid,
      }));
      console.log('Mapped properties:', newProperties);
      setLuxuryEstates(prevEstates => pageNum === 1 ? newProperties : [...prevEstates, ...newProperties]);
      setPage(pageNum);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching luxury estates:', error.response ? error.response.data : error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLuxuryEstates();
  }, []);

  useEffect(() => {
    if (activeFilter === 'Featured listings') {
      setFilteredEstates(luxuryEstates);
    } else {
      fetchNeighborhoodProperties(activeFilter);
    }
  }, [activeFilter, luxuryEstates]);

  const fetchNeighborhoodProperties = async (neighborhood) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/properties/search?search=${encodeURIComponent(neighborhood)}`);
      const validProperties = response.data.map(property => ({
        ...property,
        price: property.price ? `$${property.price.toLocaleString()}` : 'Price not available',
        sqft: property.livingArea ? `${property.livingArea.toLocaleString()} sqft` : 'N/A',
        beds: property.bedrooms || 'N/A',
        baths: property.bathrooms || 'N/A',
      })).filter(property => 
        property.latitude && 
        property.longitude && 
        typeof property.latitude === 'number' && 
        typeof property.longitude === 'number' &&
        !isNaN(property.latitude) && 
        !isNaN(property.longitude)
      );
      setFilteredEstates(validProperties);
    } catch (error) {
      console.error('Error fetching neighborhood properties:', error);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/search/${searchQuery}`);
    }
  };

  const handleSaveProperty = async (propertyId) => {
    if (!user) {
      onOpenAuthModal('signup');
      return;
    }
    try {
      const endpoint = savedProperties.includes(propertyId) ? `${process.env.REACT_APP_API_URL}/api/auth/unsave-property` : `${process.env.REACT_APP_API_URL}/api/auth/save-property`;
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: user.id, propertyId: propertyId.toString() }),
        credentials: 'include'
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.message}`);
      }
      const data = await response.json();
      console.log('Property saved/unsaved:', data);
      setSavedProperties(data.savedPosts);
      // Update user context
      updateUser({ ...user, savedPosts: data.savedPosts });
    } catch (error) {
      console.error('Error saving/unsaving property:', error);
    }
  };

  const handlePropertyClick = (property) => {
    setSelectedProperty(property);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('propertyId', property.zpid);
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
  };

  const handleCloseModal = () => {
    setSelectedProperty(null);
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('propertyId');
    navigate(`${location.pathname}${searchParams.toString() ? `?${searchParams.toString()}` : ''}`, { replace: true });
  };

  return (
    <div className="bg-black text-white font-sans">
      <GlobalHeader
        onOpenAuthModal={onOpenAuthModal}
        setSavedProperties={setSavedProperties}
        isPropertyModalOpen={!!selectedProperty}
        isAuthModalOpen={false}
      />
      <VideoHero>
        <div className="text-center px-4">
          <h1 className="text-5xl font-light mb-4 mt-20 text-center tracking-wide leading-tight">
            Curated luxury estates in Los Angeles.
          </h1>
          <p className="text-lg text-center mb-12 max-w-2xl mx-auto text-gray-400 font-light leading-relaxed">
            Explore unparalleled luxury with Heim's curated estates.
          </p>
        </div>
      </VideoHero>
      
      <SearchAndFilters
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        handleSearch={handleSearch}
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
        filters={filters}
      />
      
      <FeaturedEstates
        estates={filteredEstates}
        user={user}
        onSaveProperty={handleSaveProperty}
        savedProperties={savedProperties}
        setSavedProperties={setSavedProperties}
        updateUser={updateUser}
        onOpenAuthModal={onOpenAuthModal}
        onLoadMore={() => fetchLuxuryEstates(page + 1)}
        onPropertyClick={handlePropertyClick}
      />
    
      <main className="relative container mx-auto px-4 py-12 bg-black">
        {/* ... (rest of the content) */}
      </main>
      
      {loading && <div className="text-center py-4">Loading more properties...</div>}

      {selectedProperty && (
        <PropertyModal
          property={selectedProperty}
          onClose={handleCloseModal}
          onOpenAuthModal={onOpenAuthModal}
          user={user}
          onSaveProperty={handleSaveProperty}
          savedProperties={savedProperties}
        />
      )}
    </div>
  );
};

export default HeimLuxuryListing;