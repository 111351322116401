import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Map, { NavigationControl, Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import './App.css';
import axios from 'axios';
import { Search, Star, ChevronDown, ChevronLeft, ChevronRight, List, MapPin, Loader2 } from 'lucide-react';
import PropertyModal from './PropertyModal';
import { UserContext } from './UserContext';
import PropertyMap from './PropertyMap';
import zipCodeDataUrl from './City_of_Los_Angeles_Zip_Codes.geojson';
import neighborhoodDataUrl from './LA_Times_Neighborhood_Boundaries.geojson';
import './VideoBackground.css';

const VideoHero = ({ children }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, []);

  return (
    <div className="relative h-[20vh] video-hero-container"> {/* Keep the height at 20vh */}
      <video
        ref={videoRef}
        autoPlay
        loop
        muted
        playsInline
        className="absolute top-0 left-0 w-full h-full object-cover"
      >
        <source src="/videos/luxury-homes.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>
      <div className="absolute inset-0 flex flex-col justify-end items-center pb-4"> {/* Changed to justify-end and added pb-4 */}
        {children}
      </div>
      <div className="video-overlay"></div>
    </div>
  );
};

const SearchResults = ({ onOpenAuthModal }) => {
  const { zipcode } = useParams();
  const [properties, setProperties] = useState([]);
  const [viewState, setViewState] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [searchQuery, setSearchQuery] = useState(zipcode || "");
  const [currentSearch, setCurrentSearch] = useState(zipcode || "");
  const mapRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [hoveredProperty, setHoveredProperty] = useState(null);
  const [sortOption, setSortOption] = useState('newest');
  const { user, updateUser } = useContext(UserContext);
  const [savedProperties, setSavedProperties] = useState([]);
  const [searchedZipCode, setSearchedZipCode] = useState(null);
  const [searchedNeighborhood, setSearchedNeighborhood] = useState(null);
  const [zipCodeBoundary, setZipCodeBoundary] = useState(null);
  const [neighborhoodBoundary, setNeighborhoodBoundary] = useState(null);
  const [zipCodeData, setZipCodeData] = useState(null);
  const [neighborhoodData, setNeighborhoodData] = useState(null);
  const [zipCodeDataLoaded, setZipCodeDataLoaded] = useState(false);
  const [neighborhoodDataLoaded, setNeighborhoodDataLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [mapBounds, setMapBounds] = useState(null);
  const [currentImageIndexes, setCurrentImageIndexes] = useState({});
  const [isMapView, setIsMapView] = useState(true);
  const [selectedMapProperty, setSelectedMapProperty] = useState(null);
  const [isHeaderSticky, setIsHeaderSticky] = useState(false);
  const headerRef = useRef(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const [previewImageIndex, setPreviewImageIndex] = useState(0);
  const listRef = useRef(null);

  useEffect(() => {
    if (user) {
      setSavedProperties(user.savedPosts || []);
    } else {
      setSavedProperties([]);
    }
  }, [user]);

  useEffect(() => {
    if (currentSearch) {
      fetchProperties(currentSearch);
    }
  }, [currentSearch]);

  useEffect(() => {
    if (!user) {
      setSavedProperties([]);
    }
  }, [user]);

  useEffect(() => {
    const fetchZipCodeData = async () => {
      try {
        const response = await fetch(zipCodeDataUrl);
        const data = await response.json();
        setZipCodeData(data);
        setZipCodeDataLoaded(true);
        console.log('Zip code data loaded successfully');
      } catch (error) {
        console.error('Error loading zip code data:', error);
        setZipCodeDataLoaded(false);
      }
    };

    const fetchNeighborhoodData = async () => {
      try {
        const response = await fetch(neighborhoodDataUrl);
        const data = await response.json();
        setNeighborhoodData(data);
        setNeighborhoodDataLoaded(true);
        console.log('Neighborhood data loaded successfully');
      } catch (error) {
        console.error('Error loading neighborhood data:', error);
        setNeighborhoodDataLoaded(false);
      }
    };

    fetchZipCodeData();
    fetchNeighborhoodData();
  }, []);

  useEffect(() => {
    if (searchedZipCode && zipCodeData) {
      const matchingFeature = zipCodeData.features.find(
        feature => feature.properties.ZCTA5CE10 === searchedZipCode
      );

      if (matchingFeature) {
        setZipCodeBoundary({
          type: 'FeatureCollection',
          features: [matchingFeature]
        });
      }
    } else if (searchedNeighborhood && neighborhoodData) {
      // Normalize the searched neighborhood name
      const normalizedSearch = searchedNeighborhood.toLowerCase().replace(/[-\s]/g, '');
      
      const matchingFeature = neighborhoodData.features.find(feature => {
        // Normalize the feature name
        const normalizedFeatureName = feature.properties.name.toLowerCase().replace(/[-\s]/g, '');
        return normalizedFeatureName === normalizedSearch;
      });

      if (matchingFeature) {
        setNeighborhoodBoundary({
          type: 'FeatureCollection',
          features: [matchingFeature]
        });
      }
    }
  }, [searchedZipCode, searchedNeighborhood, zipCodeData, neighborhoodData]);

  const fetchProperties = async (query) => {
    setIsLoading(true);
    setMapBounds(null); // Reset mapBounds before fetching new properties
    try {
      console.log('Fetching properties for:', query);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/properties/search?search=${encodeURIComponent(query)}`);
      console.log('API response:', response.data);

      const validProperties = response.data.filter(property => 
        property.latitude && 
        property.longitude && 
        typeof property.latitude === 'number' && 
        typeof property.longitude === 'number' &&
        !isNaN(property.latitude) && 
        !isNaN(property.longitude)
      );

      console.log('Valid properties:', validProperties.length);

      setProperties(validProperties);
      
      if (validProperties.length > 0) {
        const bounds = validProperties.reduce(
          (acc, property) => {
            return {
              minLat: Math.min(acc.minLat, property.latitude),
              maxLat: Math.max(acc.maxLat, property.latitude),
              minLng: Math.min(acc.minLng, property.longitude),
              maxLng: Math.max(acc.maxLng, property.longitude),
            };
          },
          {
            minLat: Infinity,
            maxLat: -Infinity,
            minLng: Infinity,
            maxLng: -Infinity,
          }
        );
        const newMapBounds = [
          [bounds.minLng, bounds.minLat],
          [bounds.maxLng, bounds.maxLat]
        ];
        console.log('Setting new map bounds:', newMapBounds);
        setMapBounds(newMapBounds);
      } else {
        console.log('No valid properties, setting mapBounds to null');
        setMapBounds(null);
      }

      // Extract zip code from the query
      const zipCodeMatch = query.match(/\b\d{5}\b/);
      const newSearchedZipCode = zipCodeMatch ? zipCodeMatch[0] : null;
      setSearchedZipCode(newSearchedZipCode);

      // Set searched neighborhood if not a zip code
      if (!zipCodeMatch) {
        setSearchedNeighborhood(query);
      }
    } catch (error) {
      console.error('Error fetching properties:', error.response ? error.response.data : error.message);
      setProperties([]);
      setMapBounds(null);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setCurrentSearch(searchQuery);
    navigate(`/search/${searchQuery}`);

    try {
      const response = await axios.get(`/api/properties/search?search=${encodeURIComponent(searchQuery)}`);
      setProperties(response.data);

      if (response.data.length === 0) {
        // Reset both zip code and neighborhood boundaries if no results
        setSearchedZipCode(null);
        setSearchedNeighborhood(null);
        setZipCodeBoundary(null);
        setNeighborhoodBoundary(null);
      } else if (/^\d{5}$/.test(searchQuery)) {
        setSearchedZipCode(searchQuery);
        setSearchedNeighborhood(null);
        setNeighborhoodBoundary(null);
      } else {
        setSearchedNeighborhood(searchQuery);
        setSearchedZipCode(null);
        setZipCodeBoundary(null);
      }
    } catch (error) {
      console.error('Error fetching properties:', error);
      // Reset boundaries on error as well
      setSearchedZipCode(null);
      setSearchedNeighborhood(null);
      setZipCodeBoundary(null);
      setNeighborhoodBoundary(null);
    }
  };

  const formatPriceShort = (price) => {
    if (price >= 1000000) {
      return `$${(price / 1000000).toFixed(1)}M`;
    } else if (price >= 1000) {
      return `$${(price / 1000).toFixed(0)}K`;
    } else {
      return `$${price}`;
    }
  };

  const handlePropertyHover = (zpid) => {
    setHoveredProperty(zpid);
    // Add the hover class to the corresponding marker
    const marker = document.querySelector(`.mapboxgl-marker[data-zpid="${zpid}"]`);
    if (marker) {
      marker.classList.add('hovered');
    }
  };

  const handlePropertyLeave = () => {
    if (hoveredProperty) {
      // Remove the hover class from the previously hovered marker
      const marker = document.querySelector(`.mapboxgl-marker[data-zpid="${hoveredProperty}"]`);
      if (marker) {
        marker.classList.remove('hovered');
      }
    }
    setHoveredProperty(null);
  };

  const formatAddress = (address) => {
    if (typeof address === 'string') return address;
    return `${address.streetAddress}, ${address.city}, ${address.state} ${address.zipcode}`;
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);
  };

  const handlePropertyClick = (property) => {
    setSelectedProperty(property);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedProperty(null);
    // Remove the line that clears selectedMapProperty
    // setSelectedMapProperty(null);
  };

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
    // Smooth scroll to the top of the list
    if (listRef.current) {
      listRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  const sortedProperties = useMemo(() => {
    return [...properties].sort((a, b) => {
      switch (sortOption) {
        case 'newest':
          return b.yearBuilt - a.yearBuilt;
        case 'priceHighToLow':
          return b.price - a.price;
        case 'priceLowToHigh':
          return a.price - b.price;
        case 'bedrooms':
          return b.bedrooms - a.bedrooms;
        case 'bathrooms':
          return b.bathrooms - a.bathrooms;
        case 'squareFootage':
          return b.livingArea - a.livingArea;
        default:
          return 0;
      }
    });
  }, [properties, sortOption]);

  const handleSaveProperty = async (propertyId) => {
    if (!user) {
      onOpenAuthModal('signup');
      return;
    }
    try {
      const endpoint = savedProperties.includes(propertyId) ? `${process.env.REACT_APP_API_URL}/api/auth/unsave-property` : `${process.env.REACT_APP_API_URL}/api/auth/save-property`;
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: user.id, propertyId: propertyId.toString() }),
        credentials: 'include'
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.message}`);
      }
      const data = await response.json();
      console.log('Property saved/unsaved:', data);
      setSavedProperties(data.savedPosts);
      // Update user context
      updateUser({ ...user, savedPosts: data.savedPosts });
    } catch (error) {
      console.error('Error saving/unsaving property:', error);
    }
  };

  const handleImageScroll = (propertyZpid, direction) => {
    setCurrentImageIndexes(prevIndexes => {
      const currentIndex = prevIndexes[propertyZpid] || 0;
      const property = properties.find(p => p.zpid === propertyZpid);
      if (!property || !property.images) return prevIndexes;
      const imagesCount = property.images.length;
      let newIndex;

      if (direction === 'left') {
        newIndex = (currentIndex - 1 + imagesCount) % imagesCount;
      } else {
        newIndex = (currentIndex + 1) % imagesCount;
      }

      return { ...prevIndexes, [propertyZpid]: newIndex };
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (headerRef.current) {
        const { top } = headerRef.current.getBoundingClientRect();
        setIsHeaderSticky(top <= 0);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleMapPropertyClick = (property) => {
    setSelectedMapProperty(property);
  };

  const handleToggleView = () => {
    setIsMapView(!isMapView);
    setSelectedMapProperty(null);
  };

  const handleListingPreviewClick = () => {
    if (selectedMapProperty) {
      setSelectedProperty(selectedMapProperty);
      setIsModalVisible(true);
    }
  };

  const handleMapClick = () => {
    setSelectedMapProperty(null);
  };

  const renderFeatureTags = (property) => {
    const tags = [];
    if (property.whatsSpecial) {
      tags.push(...property.whatsSpecial);
    }
    return tags.slice(0, 3); // Limit to 3 tags to avoid overcrowding
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handlePreviewImageScroll = (direction) => {
    if (selectedMapProperty) {
      const imagesCount = selectedMapProperty.images.length;
      setPreviewImageIndex((prevIndex) => {
        if (direction === 'left') {
          return (prevIndex - 1 + imagesCount) % imagesCount;
        } else {
          return (prevIndex + 1) % imagesCount;
        }
      });
    }
  };

  const renderFloatingPreview = () => {
    if (!isMapView || !selectedMapProperty) return null;

    const style = {
      position: 'absolute',
      left: '50%',
      bottom: '10px',
      transform: 'translateX(-50%)',
      width: '80%',
      maxWidth: '300px',
      height: '290px', // Fixed height
      backgroundColor: 'white',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      zIndex: 1002,
      overflow: 'hidden'
    };

    return (
      <div style={style} onClick={handleListingPreviewClick}>
        <div className="h-2/3 relative">
          <img 
            src={selectedMapProperty.images[previewImageIndex]} 
            alt={formatAddress(selectedMapProperty.address)} 
            className="w-full h-full object-cover" 
          />
          <button
            className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full p-1"
            onClick={(e) => {
              e.stopPropagation();
              handlePreviewImageScroll('left');
            }}
          >
            <ChevronLeft size={20} />
          </button>
          <button
            className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full p-1"
            onClick={(e) => {
              e.stopPropagation();
              handlePreviewImageScroll('right');
            }}
          >
            <ChevronRight size={20} />
          </button>
        </div>
        <div className="h-1/3 p-2 flex flex-col justify-between">
          <div>
            <h3 className="text-lg font-bold text-black">{formatPrice(selectedMapProperty.price)}</h3>
            <div className="flex items-center text-sm text-gray-600">
              <span className="mr-2">{selectedMapProperty.bedrooms || 'N/A'} beds |</span>
              <span className="mr-2">{selectedMapProperty.bathrooms || 'N/A'} baths |</span>
              <span>{selectedMapProperty.livingArea ? `${selectedMapProperty.livingArea.toLocaleString()} sqft` : 'N/A'}</span>
            </div>
          </div>
          <p className="text-sm text-gray-600">{formatAddress(selectedMapProperty.address)}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-black text-white font-sans overflow-x-hidden w-full max-w-full">
      <VideoHero>
        <div className="container mx-auto px-4 z-10">
          <form onSubmit={handleSearch} className="w-full max-w-2xl mx-auto hidden sm:block">
            <div className="relative">
              <input
                type="text"
                placeholder="Search by city, state, zipcode, or address (e.g., LA, California)"
                className="w-full py-2 px-4 bg-gradient-to-b from-[#0f0f0f] to-[#1a1a1a] text-white text-base rounded-md border border-white border-opacity-20 focus:outline-none"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <button type="submit" className="absolute right-3 top-1/2 transform -translate-y-1/2">
                <Search size={20} className="text-gray-400" />
              </button>
            </div>
          </form>
        </div>
      </VideoHero>

      <div className={`sticky top-0 z-20 bg-black ${isHeaderSticky ? 'shadow-md' : ''} w-full`} ref={headerRef}>
        <div className="container mx-auto px-4 py-2 sm:hidden">
          <form onSubmit={handleSearch} className="w-full">
            <div className="relative">
              <input
                type="text"
                placeholder="Search by city, state, zipcode, or address"
                className="w-full py-2 px-4 bg-gradient-to-b from-[#0f0f0f] to-[#1a1a1a] text-white text-base rounded-md border border-white border-opacity-20 focus:outline-none"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <button type="submit" className="absolute right-3 top-1/2 transform -translate-y-1/2">
                <Search size={20} className="text-gray-400" />
              </button>
            </div>
          </form>
        </div>
        <div className="container mx-auto px-4 py-2 flex justify-between items-center">
          <h2 className="text-xl font-light">
            Showing <span className="text-[#dcb482] italic">{properties.length}</span> homes in <span className="text-[#dcb482] italic">{zipcode}</span>
          </h2>
          <div className="relative">
            <select
              value={sortOption}
              onChange={handleSortChange}
              className="bg-gray-800 text-white py-2 pl-4 pr-8 rounded-lg appearance-none focus:outline-none focus:ring-2 focus:ring-blue-400"
            >
              <option value="newest">Newest</option>
              <option value="priceHighToLow">Price (High to Low)</option>
              <option value="priceLowToHigh">Price (Low to High)</option>
              <option value="bedrooms"># of Bedrooms</option>
              <option value="bathrooms"># of Bathrooms</option>
              <option value="squareFootage">Square Footage</option>
            </select>
            <ChevronDown className="absolute right-2 top-1/2 transform -translate-y-1/2 pointer-events-none" size={20} />
          </div>
        </div>
      </div>

      <div className="w-full max-w-full px-4 py-6">
        <div className="flex flex-col lg:flex-row gap-6">
          <div className={`lg:w-1/2 lg:sticky lg:top-6 h-[calc(100vh-200px)] ${isMapView ? 'block' : 'hidden sm:block'}`}>
            {zipCodeDataLoaded && neighborhoodDataLoaded && !isLoading ? (
              <div className="relative h-full">
                <PropertyMap
                  properties={properties}
                  onPropertyClick={handleMapPropertyClick}
                  hoveredProperty={hoveredProperty}
                  onPropertyHover={handlePropertyHover}
                  onPropertyLeave={handlePropertyLeave}
                  savedProperties={savedProperties}
                  onSaveProperty={handleSaveProperty}
                  searchedZipCode={searchedZipCode}
                  searchedNeighborhood={searchedNeighborhood}
                  zipCodeBoundary={zipCodeBoundary}
                  neighborhoodBoundary={neighborhoodBoundary}
                  zipCodeData={zipCodeData}
                  neighborhoodData={neighborhoodData}
                  mapBounds={mapBounds}
                  onClick={handleMapClick}
                  isMobile={isMobile}
                  selectedProperty={selectedMapProperty ? selectedMapProperty.zpid : null}
                />
                {renderFloatingPreview()}
              </div>
            ) : (
              <div>Loading map data...</div>
            )}
          </div>
          
          <div 
            ref={listRef}
            className={`lg:w-1/2 h-[calc(100vh-200px)] overflow-y-auto overflow-x-hidden smooth-scroll ${isMapView ? 'hidden sm:block' : 'block'}`}
          >
            {isLoading ? (
              <div>Loading properties...</div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pt-1 w-full">
                {sortedProperties.map((property) => {
                  const currentImageIndex = currentImageIndexes[property.zpid] || 0;
                  return (
                    <div 
                      key={property.zpid}
                      className="card-wrapper group pointer-events-auto relative grid aspect-square h-full w-full grid-rows-[1fr_auto] gap-3 rounded-lg sm:aspect-auto sm:grid-rows-[294px_auto] p-1 transform transition-all duration-300 hover:scale-[1.025] hover:shadow-lg cursor-pointer"
                      onClick={() => handlePropertyClick(property)}
                      onMouseEnter={() => handlePropertyHover(property.zpid)}
                      onMouseLeave={handlePropertyLeave}
                    >
                      <div className="card relative isolate h-full w-full rounded-lg overflow-hidden">
                        <div className="scrollbar-hide pointer-events-auto relative inset-0 flex snap-x snap-mandatory overflow-x-auto w-full object-cover h-full">
                          <img 
                            src={property.images[currentImageIndex]} 
                            alt={formatAddress(property.address)} 
                            className="h-full w-full flex-none snap-center snap-always overflow-hidden object-cover"
                          />
                        </div>
                        <button
                          className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full p-1.5 hover:bg-opacity-75 transition-opacity opacity-0 group-hover:opacity-100 z-10 w-8 h-8 flex items-center justify-center"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleImageScroll(property.zpid, 'left');
                          }}
                          aria-label="Previous image"
                        >
                          <ChevronLeft size={20} />
                        </button>
                        <button
                          className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full p-1.5 hover:bg-opacity-75 transition-opacity opacity-0 group-hover:opacity-100 z-10 w-8 h-8 flex items-center justify-center"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleImageScroll(property.zpid, 'right');
                          }}
                          aria-label="Next image"
                        >
                          <ChevronRight size={20} />
                        </button>
                        <button 
                          className="absolute top-2 right-2 text-white hover:text-yellow-500 transition-colors duration-200 z-20"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSaveProperty(property.zpid);
                          }}
                        >
                          <Star 
                            size={24} 
                            fill={savedProperties.includes(property.zpid) ? "currentColor" : "none"} 
                          />
                        </button>
                        <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-3">
                          <h3 className="text-xl font-bold mb-1">{formatPrice(property.price)}</h3>
                          <p className="text-sm">{formatAddress(property.address)}</p>
                          <div className="flex items-center text-xs mt-1">
                            <span className="mr-2">{property.bedrooms || 'N/A'} beds</span>
                            <span className="mr-2">{property.bathrooms || 'N/A'} baths</span>
                            {property.livingArea && (
                              <span>{property.livingArea.toLocaleString()} sqft</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>

      {!selectedMapProperty && !selectedProperty && (
        <button
          className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-white text-black py-2 px-4 rounded-full shadow-lg flex items-center sm:hidden z-[1001]"
          onClick={handleToggleView}
        >
          {isMapView ? (
            <>
              <List size={20} className="mr-2" />
              List View
            </>
          ) : (
            <>
              <MapPin size={20} className="mr-2" />
              Map View
            </>
          )}
        </button>
      )}

      {selectedProperty && (
        <div className={`fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-[1002] transition-opacity duration-300 ${isModalVisible ? 'opacity-100' : 'opacity-0'}`}>
          <PropertyModal
            property={selectedProperty}
            onClose={handleCloseModal}
            onOpenAuthModal={onOpenAuthModal}
            user={user}
            onSaveProperty={handleSaveProperty}
            savedProperties={savedProperties}
          />
        </div>
      )}
    </div>
  );
};

export default SearchResults;