import React, { useContext, useState, useRef, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Search, Menu, LogOut, Bookmark } from 'lucide-react';
import { UserContext } from '../UserContext';
import { useScrollPosition } from '../hooks/useScrollPosition';

const GlobalHeader = ({ onOpenAuthModal, setSavedProperties, isPropertyModalOpen, isAuthModalOpen }) => {
  const { user, updateUser } = useContext(UserContext);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const userMenuRef = useRef(null);
  const navigate = useNavigate();
  const scrollPosition = useScrollPosition();
  const location = useLocation();

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  const handleLogout = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/logout`, {
        method: 'POST',
        credentials: 'include'
      });
      if (response.ok) {
        updateUser(null);
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        setSavedProperties([]);
        setIsUserMenuOpen(false);
        navigate('/');
      }
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const isSearchOrSavedPage = location.pathname.includes('/search') || location.pathname.includes('/saved-homes');
  const shouldApplyBlur = isSearchOrSavedPage && scrollPosition > 50;

  return (
    <>
      <header className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 ${
        shouldApplyBlur ? 'bg-black/50 backdrop-blur-md' : 'bg-transparent'
      } pointer-events-none ${
        isPropertyModalOpen || isAuthModalOpen ? 'hidden' : ''
      }`}>
        <div className="container mx-auto px-4 py-4 flex items-center justify-between h-16">
          <Link to="/" className="text-2xl font-light tracking-wider text-white pointer-events-auto">HEIM</Link>
          <div className="flex items-center space-x-4 pointer-events-auto">
            {user ? (
              <>
                <div className="hidden lg:block relative" ref={userMenuRef}>
                  <button 
                    onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}
                    className="flex items-center space-x-2 text-white hover:text-gray-300 h-10"
                  >
                    <img 
                      src={user.googleProfilePhoto || 
                        (user.avatar ? `${process.env.REACT_APP_API_URL}${user.avatar}` : '/LuxuryRE_Profile_photos_svg/1.svg')} 
                      alt={user.firstName} 
                      className="w-10 h-10 rounded-full"
                      onError={(e) => {
                        console.error('Error loading avatar:', e);
                        e.target.src = '/LuxuryRE_Profile_photos_svg/1.svg';
                      }}
                    />
                    <span>Welcome, {user.firstName}</span>
                  </button>
                  {isUserMenuOpen && (
                    <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                      <Link to="/saved-homes" className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                        <Bookmark className="w-4 h-4 mr-2" />
                        View Saved Homes
                      </Link>
                      <button onClick={handleLogout} className="flex items-center w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                        <LogOut className="w-4 h-4 mr-2" />
                        Logout
                      </button>
                    </div>
                  )}
                </div>
                <button 
                  onClick={toggleMobileMenu}
                  className="lg:hidden z-[110] relative w-8 h-8 flex flex-col justify-center items-end"
                  aria-label="Navigation toggle"
                >
                  <span className={`bg-white h-0.5 rounded-full w-6 transition-all duration-300 ease-in-out transform ${isMobileMenuOpen ? 'rotate-45 translate-y-1.5' : ''}`}></span>
                  <span className={`bg-white h-0.5 rounded-full w-5 mt-1.5 transition-all duration-300 ease-in-out transform ${isMobileMenuOpen ? 'opacity-0 translate-x-3' : ''}`}></span>
                  <span className={`bg-white h-0.5 rounded-full w-4 mt-1.5 transition-all duration-300 ease-in-out transform ${isMobileMenuOpen ? '-rotate-45 -translate-y-1.5 w-6' : ''}`}></span>
                </button>
              </>
            ) : (
              <>
                <div className="hidden lg:flex items-center space-x-4 h-8">
                  <button onClick={() => onOpenAuthModal('signup')} className="bg-white text-black px-4 py-2 rounded-full text-sm font-medium">
                    Sign Up
                  </button>
                  <button onClick={() => onOpenAuthModal('login')} className="bg-transparent text-white px-4 py-2 rounded-full text-sm font-medium border border-white">
                    Log In
                  </button>
                </div>
                <button 
                  onClick={toggleMobileMenu}
                  className="lg:hidden z-[110] relative w-8 h-8 flex flex-col justify-center items-end"
                  aria-label="Navigation toggle"
                >
                  <span className={`bg-white h-0.5 rounded-full w-6 transition-all duration-300 ease-in-out transform ${isMobileMenuOpen ? 'rotate-45 translate-y-1.5' : ''}`}></span>
                  <span className={`bg-white h-0.5 rounded-full w-5 mt-1.5 transition-all duration-300 ease-in-out transform ${isMobileMenuOpen ? 'opacity-0 translate-x-3' : ''}`}></span>
                  <span className={`bg-white h-0.5 rounded-full w-4 mt-1.5 transition-all duration-300 ease-in-out transform ${isMobileMenuOpen ? '-rotate-45 -translate-y-1.5 w-6' : ''}`}></span>
                </button>
              </>
            )}
          </div>
        </div>
      </header>
      {isMobileMenuOpen && (
        <div className="fixed inset-0 bg-black/90 z-[100] flex flex-col lg:hidden">
          <button 
            onClick={toggleMobileMenu}
            className="absolute top-4 right-4 w-8 h-8 flex flex-col justify-center items-end z-[110]"
            aria-label="Close navigation menu"
          >
            <span className="bg-white h-0.5 rounded-full w-6 rotate-45 translate-y-0.5 transition-all duration-300"></span>
            <span className="bg-white h-0.5 rounded-full w-6 -rotate-45 -translate-y-0.5 transition-all duration-300"></span>
          </button>
          {user ? (
            <div className="flex flex-col items-start w-full pt-20 px-6">
              <div className="flex items-center space-x-4 mb-8">
                <img 
                  src={user.googleProfilePhoto || 
                    (user.avatar ? `${process.env.REACT_APP_API_URL}${user.avatar}` : '/LuxuryRE_Profile_photos_svg/1.svg')} 
                  alt={user.firstName} 
                  className="w-16 h-16 rounded-full"
                  onError={(e) => {
                    console.error('Error loading avatar:', e);
                    e.target.src = '/LuxuryRE_Profile_photos_svg/1.svg';
                  }}
                />
                <div className="text-white">
                  <div className="text-lg font-medium">Welcome,</div>
                  <div className="text-xl font-bold">{user.firstName}</div>
                </div>
              </div>
              <Link 
                to="/saved-homes" 
                className="flex items-center text-white text-lg mb-4"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                <Bookmark className="w-6 h-6 mr-2" />
                Saved Listings
              </Link>
              <Link 
                to="/"
                onClick={() => {
                  handleLogout();
                  setIsMobileMenuOpen(false);
                }}
                className="flex items-center text-white text-lg"
              >
                <LogOut className="w-6 h-6 mr-2" />
                Logout
              </Link>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center h-full">
              <button 
                onClick={() => {
                  onOpenAuthModal('signup');
                  setIsMobileMenuOpen(false);
                }}
                className="bg-white text-black px-6 py-3 rounded-full text-lg font-medium mb-4 w-64"
              >
                Sign Up
              </button>
              <button 
                onClick={() => {
                  onOpenAuthModal('login');
                  setIsMobileMenuOpen(false);
                }}
                className="bg-transparent text-white px-6 py-3 rounded-full text-lg font-medium border border-white w-64"
              >
                Log In
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default GlobalHeader;